import { useConditionStore } from './conditions.store';
import {
  AppraisalDocument,
  AppraisalQuery,
  AppraisalQueryVariables,
  useEditConditionPhotoMutation,
  useGetAppraisalMutation,
  useUploadAppraisalPhotoReactiveMutation,
} from 'graphql-types';
import ComplexIdGenerator from 'utils/ComplexIdGenerator';
import { ConditionIdType, ConditionPhotosItem } from './types';
import { useApolloClient } from '@apollo/client';

const useUploadConditionItem = () => {
  const [getAppraisal] = useGetAppraisalMutation();
  const [uploadAppraisalPhoto] = useUploadAppraisalPhotoReactiveMutation();
  const { forceRemoveCondition, removePhotos, setLoading } =
    useConditionStore();
  const [updateConditionPhoto] = useEditConditionPhotoMutation();
  const client = useApolloClient();

  const uploadConditionItem = async ({
    appraisalId,
    photos,
    conditionId,
    conditionObjectId,
  }: ConditionPhotosItem) => {
    try {
      setLoading(appraisalId, conditionId, true);

      /*
        Step 1. Fetch an appraisal data
      */
      const { data } = await getAppraisal({
        variables: { id: appraisalId.toString() },
      });

      /*
        Step 2. Check is appraisal still exists?

        Yes -> Proceed to Step 3.
        No -> Remove condition from IndexDB
      */

      if (!data?.getAppraisal) {
        setLoading(appraisalId, conditionId, false);
        throw new Error('Unable to fetch appraisal');
      }

      /*
        Step 3. Check is condition still exists

        Yes -> Proceed to Step 4.
        No -> Remove condition from IndexDB
      */

      const { label, pinX, pinY } =
        ComplexIdGenerator.parse<ConditionIdType>(conditionId);

      const selectedCondition = data.getAppraisal.result.conditions.find(
        (condition) =>
          condition.label === label &&
          condition.pin.x === pinX &&
          condition.pin.y === pinY
      );

      if (!selectedCondition) {
        /*
         The condition has been removed from server, so there is no need to upload it`s photos
      */

        setLoading(appraisalId, conditionId, false);
        return forceRemoveCondition(appraisalId, conditionId);
      }

      /*
      Step 2. Upload photos one by one and push each on server
     */

      for (const photo of photos) {
        const uploadedPhoto = await uploadAppraisalPhoto({
          variables: { id: appraisalId, file: photo },
        });

        const srcS3 =
          uploadedPhoto.data?.uploadAppraisalPhotoReactive.result.src || '';

        await updateConditionPhoto({
          variables: {
            reportId: data.getAppraisal.result._id,
            conditionId: conditionObjectId,
            photoId: photo._id,
            input: {
              id: photo.id,
              zone: photo.zone,
              src: srcS3,
            },
          },
        });

        const query = client.cache.readQuery<
          AppraisalQuery,
          AppraisalQueryVariables
        >({
          query: AppraisalDocument,
          variables: { id: appraisalId.toString() },
        });

        if (query) {
          const updatedQuery: AppraisalQuery = {
            ...query,

            appraisal: {
              ...query.appraisal,
              result: {
                ...query.appraisal.result,
                conditions: [
                  ...query.appraisal.result.conditions.map((cond) => {
                    if (cond._id === conditionObjectId) {
                      return {
                        ...cond,
                        photos: cond.photos.map((image) =>
                          image._id === photo._id
                            ? { ...image, src: srcS3 }
                            : image
                        ),
                      };
                    }

                    return cond;
                  }),
                ],
              },
            },
          };

          client.cache.writeQuery<AppraisalQuery, AppraisalQueryVariables>({
            query: AppraisalDocument,
            variables: { id: appraisalId.toString() },
            data: updatedQuery,
          });
        }

        removePhotos(appraisalId, conditionId, [photo.serverSrcId]);
      }
    } catch (err) {
      throw Error('error');
    } finally {
      setLoading(appraisalId, conditionId, false);
    }
  };

  return { uploadConditionItem };
};

export default useUploadConditionItem;
