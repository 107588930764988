import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

export enum Keys {
  MountedOn = 'mountedOn',
  Season = 'season',
  RearRight = 'rearRight',
  RearLeft = 'rearLeft',
  FrontRight = 'frontRight',
  FrontLeft = 'frontLeft',

  SecondMountedOn = 'secondSet.mountedOn',
  SecondSeason = 'secondSet.season',
  SecondRearRight = 'secondSet.rearRight',
  SecondRearLeft = 'secondSet.rearLeft',
  SecondFrontRight = 'secondSet.frontRight',
  SecondFrontLeft = 'secondSet.frontLeft',
}

const threadObject = {
  [Keys.MountedOn]: z.string(),
  [Keys.Season]: z.string(),
  [Keys.RearRight]: z.string(),
  [Keys.RearLeft]: z.string(),
  [Keys.FrontRight]: z.string(),
  [Keys.FrontLeft]: z.string(),
};

export const Schema = z.object({
  ...threadObject,
  secondSet: z.object(threadObject),
});

export type FormType = z.infer<typeof Schema>;

export const defaultValues: FormType = {
  [Keys.MountedOn]: '',
  [Keys.Season]: '',
  [Keys.RearRight]: '',
  [Keys.RearLeft]: '',
  [Keys.FrontRight]: '',
  [Keys.FrontLeft]: '',
  secondSet: {
    [Keys.MountedOn]: '',
    [Keys.Season]: '',
    [Keys.RearRight]: '',
    [Keys.RearLeft]: '',
    [Keys.FrontRight]: '',
    [Keys.FrontLeft]: '',
  },
};

export const useThreadForm = (values: FormType = defaultValues) => {
  return useForm<FormType>({
    mode: 'onBlur',
    resolver: zodResolver(Schema),
    defaultValues: { ...defaultValues, ...values },
  });
};
