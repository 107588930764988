import { create } from 'zustand';
import { AuthUser, Countries } from 'graphql-types';
import { useShallow } from 'zustand/react/shallow';
import { RolesWithSockets } from '../../constants/entities/static';
import { UserRoles } from '../../constants/entities/user';

type UserStore = {
  user: AuthUser;
  setUser: (user: AuthUser) => void;
  isOwner: (userId: string | number) => boolean;
  isHasAccess: (permission: string) => boolean;
  isSocketUsing: () => boolean;
  checkRole: (role: UserRoles) => boolean;
};

export const defaultUser: AuthUser = {
  __typename: 'AuthUser' as const,
  id: 0,
  country: Countries.US,
  dealership: '',
  email: '',
  firstName: '',
  lastName: '',
  permissions: [],
  phoneNumber: '',
  roles: [],
};

const useUserStore = create<UserStore>((set, get) => ({
  user: defaultUser,
  setUser: (user) => set({ user }),

  isOwner: (userId) => {
    return Number(get().user.id) === Number(userId);
  },

  isHasAccess: (permission: string): boolean => {
    if (!permission) return true;

    const [module, action, resource] = permission.split(':');

    const all = (get().user.permissions || []).flatMap((it) => {
      const [p_module, p_action, p_resources] = it.split(':');

      if (p_module === '*' || p_module === module) {
        if (p_action == '*') return p_resources.split(',');

        if (p_action === action || !action) return p_resources.split(',');

        return [];
      }

      return [];
    });

    return resource ? all.includes(resource) : all.length > 0;
  },

  isSocketUsing: () => {
    const user = get().user;
    return RolesWithSockets.some((role) => user.roles?.includes(role as any));
  },

  checkRole: (role: UserRoles) => {
    return get().user.roles?.includes(role);
  },
}));

export const useUserChecks = () =>
  useUserStore(
    useShallow(({ isOwner, isHasAccess, isSocketUsing, checkRole }) => ({
      isOwner,
      isHasAccess,
      isSocketUsing,
      checkRole,
    }))
  );

export const useUser = () => useUserStore(useShallow((state) => state.user));

export default useUserStore;
