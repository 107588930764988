import React, { FC, PropsWithChildren } from 'react';
import useFeaturesInit from './features/useFeaturesInit';
import Loading from 'ui/components/loading';
import useUserInit from './user/useUserInit';
import useSettingsInit from './settings/useSettingsInit';
import useSyncDB from './offline-changes/useSyncDB';
import useStoreDB from './offline-changes/useStoreDB';

const StoreInitializer: FC<PropsWithChildren> = ({ children }) => {
  const { isFeaturesLoading } = useFeaturesInit();
  const { isUserLoading } = useUserInit();
  useSettingsInit();
  useSyncDB();
  useStoreDB();

  const isLoading = isFeaturesLoading || isUserLoading;

  if (isLoading) {
    return <Loading />;
  }

  return <>{children}</>;
};

export default StoreInitializer;
