// Import necessary modules
import React from 'react';
import { styled, ThemeProvider } from '@mui/material/styles';
import { Typography, Button, Container, CssBaseline } from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useNavigate } from 'react-router-dom';
import { RoutesConfig } from '../../router/routes.config';
import AppErrorLayout from '../../ui/layouts/AppLayout/AppErrorLayout';
import themes from '../../styles/app-themes';
import GlobalCss from '../../styles/GlobalCss';

// Styled Components
const ErrorContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100vh',
  textAlign: 'center',
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
}));

const ErrorIcon = styled(ErrorOutlineIcon)(({ theme }) => ({
  fontSize: '6rem',
  color: theme.palette.error.main,
  marginBottom: theme.spacing(2),
}));

const RetryButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

// Error Page Component
export default function ErrorPage() {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate(RoutesConfig.Dashboard);
  };

  return (
    <ThemeProvider theme={themes.hgregoire}>
      <GlobalCss />
      <CssBaseline />

      <AppErrorLayout>
        <ErrorContainer>
          <ErrorIcon />
          <Typography variant="h4" gutterBottom>
            Oops! An error occurred.
          </Typography>
          <Typography variant="body1" gutterBottom>
            It seems something went wrong. Don’t worry, it happens to the best
            of us.
          </Typography>

          <RetryButton
            variant="contained"
            color="primary"
            onClick={handleBackClick}
          >
            Go Back
          </RetryButton>
        </ErrorContainer>
      </AppErrorLayout>
    </ThemeProvider>
  );
}
