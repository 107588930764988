import React, { FC } from 'react';
import { FormControlLabel, Radio, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import OptionButton from './OptionButton';
import { RadioListProps } from 'constants/entities/ui/types';

const BasicRadioList: FC<RadioListProps> = ({
  options,
  currentValue,
  OptionComponent = OptionButton,
  direction = 'row',
  disabled,
}) => {
  const { t } = useTranslation();

  return (
    <Stack flexDirection={direction} gap={2} flexWrap="wrap">
      {options.map((option) => (
        <FormControlLabel
          key={option.value}
          value={option.value}
          control={<Radio disableRipple sx={{ display: 'none' }} />}
          disabled={disabled}
          label={
            <OptionComponent
              isActive={option.value === currentValue}
              option={option}
              data-cy={`option-${option.value}`}
            >
              {t(option.label)}
            </OptionComponent>
          }
        />
      ))}
    </Stack>
  );
};

export default BasicRadioList;
