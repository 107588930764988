import React, { PropsWithChildren, useState } from 'react';
import clsx from 'clsx';

import { Box, Icon, IconButton, Stack, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

import LayoutSuspense from '../layout-suspend';
import Sidenav from '../sidenav';
import VerticalNav from '../vertical-nav';
import { navigations } from '../navigationsMenu';

const useStyles = makeStyles((theme) => ({
  contentWrap: ({ width }: { width: string }) => ({
    verticalAlign: 'top',
    marginLeft: width,
    transition: 'all 0.3s ease',
    height: '100dvh',
  }),
  topbar: {
    top: 0,
    zIndex: 96,
    boxShadow: '0 1px 9px -3px rgba(0,0,0,.1)',
    height: 64,
    paddingLeft: 18,
    paddingRight: 20,
    background:
      'linear-gradient(180deg, rgba(255, 255, 255, 0.95) 44%, rgba(247, 247, 247, 0.4) 50%, rgba(255, 255, 255, 0))',
    [theme.breakpoints.down('md')]: {
      paddingLeft: 16,
      paddingRight: 16,
    },
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 14,
      paddingRight: 16,
    },
  },
}));

const AppErrorLayout = (props: PropsWithChildren) => {
  const theme = useTheme();
  const isMdScreen = useMediaQuery(theme.breakpoints.down('lg'));

  const [sidebarMode, changeMode] = useState<SidebarMode>('close');

  const handleSidebarToggle = () => {
    if (isMdScreen) {
      changeMode(sidebarMode === 'close' ? 'mobile' : 'close');
    } else {
      changeMode(sidebarMode === 'full' ? 'close' : 'full');
    }
  };

  const getSidenavWidth = () => {
    switch (sidebarMode) {
      case 'full':
        return 'var(--sidenav-width)';
      case 'compact':
        return 'var(--sidenav-compact-width)';
      default:
        return '0px';
    }
  };

  const sidenavWidth = getSidenavWidth();
  const classes = useStyles({ width: sidenavWidth });

  const navigationsFilter = [navigations[0], navigations[1]];

  return (
    <div className={'bg-default'}>
      {sidebarMode !== 'close' && (
        <Sidenav mode={sidebarMode} onOverlayClick={() => changeMode('close')}>
          <VerticalNav items={navigationsFilter} />
        </Sidenav>
      )}

      <div
        className={clsx(
          'flex-grow flex-column relative overflow-hidden h-full-screen',
          classes.contentWrap
        )}
      >
        <div className={classes.topbar}>
          <div className="flex justify-between items-center h-full">
            <Stack direction="row" alignItems="center">
              <IconButton onClick={handleSidebarToggle} size="large">
                <Icon>menu</Icon>
              </IconButton>
            </Stack>
          </div>
        </div>

        <Box
          className="flex-grow flex-column relative h-full scroll-y"
          sx={{ bgcolor: 'grey.300' }}
        >
          <div className="relative flex-grow">
            <Box
              id="layout"
              className="flex-grow flex-column relative h-full"
              p={{ xs: 1, sm: 1, md: 3 }}
              px={{ xs: 0 }}
            >
              <LayoutSuspense>{props.children}</LayoutSuspense>
            </Box>
          </div>
        </Box>
      </div>
    </div>
  );
};

export default AppErrorLayout;
