import { useGetMonkaiTokenLazyQuery } from 'graphql-types';
import { useEffect, useMemo, useState } from 'react';
import monk from '@monkvision/corejs';
import useStore from 'store/pe-edit-appraisal.store';
import { useToggle } from 'hooks';
import { useFeedback } from 'hooks/useFeedback';
import { nanoid } from '@reduxjs/toolkit';

let refetchedCount = 0;
const MAX_REFETCH_COUNT = 2;

const useMonkToken = () => {
  const { appraisal } = useStore();
  const [isLoading, { on, off }] = useToggle();
  const { shwoError, showInfo } = useFeedback();

  const [errorKey, setErrorKey] = useState<null | string>(null);

  const onError = () => {
    shwoError('Monkai service is unavailable. Try to reload the page.');
  };

  const onLoading = () => {
    showInfo('Monkai service is still loading. It can take a few minutes.');
  };

  const [fetchToken, { data, loading, error }] = useGetMonkaiTokenLazyQuery({
    variables: { id: `${appraisal?.id}` },
    onError: () => {
      if (refetchedCount >= MAX_REFETCH_COUNT) {
        off();
        setErrorKey(null);
        onError();
      } else {
        setErrorKey(nanoid(10));
      }
    },

    onCompleted: off,
  });

  const monkToken = useMemo(() => data?.getMonkaiToken?.token, [data]);

  useEffect(() => {
    if (monkToken) {
      monk.config.accessToken = monkToken;
    }
  }, [monkToken]);

  useEffect(() => {
    if (errorKey && refetchedCount < MAX_REFETCH_COUNT) {
      on();

      setTimeout(() => {
        refetchedCount++;
        fetchToken();
      }, 15000);
    }
  }, [errorKey]);

  useEffect(() => {
    fetchToken();
  }, []);

  return {
    loading: loading || isLoading,
    error,
    monkToken,
    onError,
    onLoading,
  };
};

export default useMonkToken;
