import { create } from 'zustand';
import { InspectionsStore } from './types';
import InspectionsEvent from './InspectionsEvent';

export const useInspectionsStore = create<InspectionsStore>((set, get) => ({
  inspections: [],
  setInspections: (inspections) => {
    set({ inspections });
  },

  getInspection: (appraisalId, inspectionId) => {
    return get().inspections.find(
      (c) => c.appraisalId === appraisalId && c.inspectionId === inspectionId
    );
  },

  isInspectionOffline: (appraisalId, inspectionId) => {
    return get().inspections.some(
      (c) =>
        c.appraisalId === appraisalId &&
        c.inspectionId === inspectionId &&
        c.photos.length
    );
  },

  saveInspection: (item) => {
    set((state) => {
      // Check if the condition already exists
      const existingConditionIndex = state.inspections.findIndex(
        ({ appraisalId, inspectionId }) =>
          appraisalId === item.appraisalId && inspectionId === item.inspectionId
      );

      if (existingConditionIndex !== -1) {
        // If it exists, merge the photos
        const existingCondition = state.inspections[existingConditionIndex];

        const updatedPhotos = [
          ...existingCondition.photos,
          ...item.photos, // Combine existing photos with new ones
        ];

        // Update the condition with combined photos
        const updatedCondition = {
          ...existingCondition,
          photos: updatedPhotos,
        };

        if (item.photos.length) {
          InspectionsEvent.emit('savedInspection', {
            ...item,
            isUploading: false,
            isStartedInspection: false,
          });
        }

        // Return updated conditionPhotos with the merged photos
        return {
          inspections: [
            ...state.inspections.slice(0, existingConditionIndex),
            updatedCondition,
            ...state.inspections.slice(existingConditionIndex + 1),
          ],
        };
      } else {
        InspectionsEvent.emit('savedInspection', {
          ...item,
          isUploading: false,
          isStartedInspection: false,
        });

        // If it doesn't exist, just add the new item with isUploading: false

        return {
          inspections: [
            { ...item, isUploading: false, isStartedInspection: false },
            ...state.inspections,
          ],
        };
      }
    });
  },

  removeInspection: (appraisalId, inspectionId) => {
    set((state) => ({
      inspections: state.inspections.filter(
        (c) =>
          !(
            c.inspectionId === inspectionId &&
            c.appraisalId === appraisalId &&
            !c.photos.length
          )
      ),
    }));
  },

  startInspection: (appraisalId, inspectionId) => {
    set((state) => ({
      inspections: state.inspections.map((c) =>
        c.inspectionId === inspectionId && c.appraisalId === appraisalId
          ? { ...c, isStartedInspection: true }
          : c
      ),
    }));
  },

  forceRemoveInspection: (appraisalId, inspectionId) => {
    set((state) => ({
      inspections: state.inspections.filter(
        (c) =>
          !(c.inspectionId === inspectionId && c.appraisalId === appraisalId)
      ),
    }));
  },

  removePhotos: (appraisalId, inspectionId, removeServerIds) => {
    set((state) => ({
      inspections: state.inspections.map((condition) => {
        const isSameCondition =
          condition.inspectionId === inspectionId &&
          condition.appraisalId === appraisalId;

        if (isSameCondition) {
          return {
            ...condition,
            photos: condition.photos.filter(
              ({ serverSrcId }) => !removeServerIds.includes(serverSrcId)
            ),
          };
        }

        return condition;
      }),
    }));
  },

  setLoading: (appraisalId, inspectionId, isUploading) => {
    set((state) => ({
      inspections: state.inspections.map((condition) =>
        condition.appraisalId === appraisalId &&
        condition.inspectionId === inspectionId
          ? { ...condition, isUploading }
          : condition
      ),
    }));
  },

  getPhotoSrc: (serverSrcId) => {
    return get().inspections.reduce<string | null>((acc, ins) => {
      const photo = ins.photos.find((p) => p.serverSrcId === serverSrcId);

      if (photo) {
        return photo.src;
      }

      return acc;
    }, null);
  },
}));
