import { Appraisal, AppraisalInput } from 'graphql-types';
import { create } from 'zustand';

type StoreType = {
  appraisal: Partial<Appraisal> | null;
  setDefault: (appraisal: Partial<Appraisal>) => void;
  clearAppraisal: () => void;
  updateAppraisal: (appraisal: Partial<AppraisalInput>) => void;
  setAppraisalPhoto: (appraisalPhoto: any) => void;

  isSkipEquipment: boolean;
  skipEquipmentOn: () => void;
  skipEquipmentOff: () => void;
};

const useStore = create<StoreType>((set) => ({
  appraisal: null,
  isSkipEquipment: false,

  clearAppraisal: () => {
    set(() => ({
      appraisal: null,
    }));
  },
  setDefault: (appraisal) => {
    set(() => ({
      appraisal,
    }));
  },
  updateAppraisal: (appraisal) => {
    set((state) => ({
      appraisal: {
        ...state.appraisal,
        ...(appraisal as any),
      },
    }));
  },
  setAppraisalPhoto: (newPhoto: any) => {
    set((state) => {
      if (!state?.appraisal || newPhoto.id !== state?.appraisal?.id) {
        return state;
      }
      const photos = [...state.appraisal.photos!];
      const existElementIndx = photos!.findIndex(
        (obj) => obj.zone === newPhoto.zone
      );

      if (existElementIndx !== -1) {
        photos!.splice(existElementIndx, 1, newPhoto);
      } else {
        photos.push(newPhoto);
      }

      return {
        appraisal: {
          ...state.appraisal,
          photos: [...photos!],
        },
      };
    });
  },

  skipEquipmentOn: () => {
    set(() => ({ isSkipEquipment: true }));
  },

  skipEquipmentOff: () => {
    set(() => ({ isSkipEquipment: false }));
  },
}));
export default useStore;
