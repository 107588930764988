import { useConditionStore } from './conditions/conditions.store';
import { useEffect, useRef } from 'react';
import localForage from 'localforage';
import { useInspectionsStore } from './inspections/inspections.store';

const KEY = 'condition-report-store';

const useStoreDB = () => {
  const { setConditionPhotos, conditionPhotos } = useConditionStore();
  const { setInspections, inspections } = useInspectionsStore();

  const initializedRef = useRef(false);

  // Load data from IndexedDB and set it in Zustand store
  const init = async () => {
    try {
      const photosJSON = await localForage.getItem(KEY);
      const parsedData = JSON.parse(photosJSON as string);

      const conditionPhotos = parsedData?.state?.conditionPhotos ?? [];
      setConditionPhotos(conditionPhotos);

      const inspectionPhotos = parsedData?.state?.inspections ?? [];
      setInspections(inspectionPhotos);

      initializedRef.current = true; // Mark initialization as complete
    } catch (error) {
      console.error('Failed to load photos from IndexedDB:', error);
    }
  };

  // Save Zustand state to IndexedDB
  const push = async () => {
    try {
      const JSONString = JSON.stringify({
        state: { conditionPhotos, inspections },
      });

      await localForage.setItem(KEY, JSONString);
    } catch (error) {
      console.error('Failed to save photos to IndexedDB:', error);
    }
  };

  useEffect(() => {
    init(); // Load data on mount
  }, []);

  useEffect(() => {
    // Push updates to IndexedDB only after initialization
    if (initializedRef.current) {
      push();
    }
  }, [conditionPhotos, inspections]);
};

export default useStoreDB;
