import sightsData from '@monkvision/sights/dist';
import axios from 'axios';
import monk from '@monkvision/corejs';

const useUploadMonkaiImage = () => {
  const handleUploadMonkaiImage = async (
    inspectionId: string,
    picture: any,
    sight: string
  ) => {
    const metadata = sightsData[sight];
    const { id } = metadata;

    const fileType = picture.fileType;
    const filename = `${id}-${inspectionId}.${picture.imageFilenameExtension}`;
    const multiPartKeys = {
      image: 'image',
      json: 'json',
      type: fileType,
      filename,
    };

    const json = JSON.stringify({
      acquisition: {
        strategy: 'upload_multipart_form_keys',
        file_key: multiPartKeys.image,
      },
      compliances: {
        image_quality_assessment: {},
        coverage_360: undefined,
      },
      tasks: ['damage_detection'],
      additional_data: {
        ...metadata,
        overlay: undefined,
        createdAt: new Date(),
      },
    });

    const res = await axios.get(picture.uri, { responseType: 'blob' });

    const file = new File([res.data], multiPartKeys.filename, {
      type: multiPartKeys.type,
    });

    const data = new FormData();
    data.append(multiPartKeys.json, json);
    data.append(multiPartKeys.image, file);

    return monk.entity.image.addOne(inspectionId, data);
  };

  return { handleUploadMonkaiImage };
};

export default useUploadMonkaiImage;
