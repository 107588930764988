import { EventEmitter } from 'events';

import { InspectionItem } from './types';

interface AppEvents {
  savedInspection: InspectionItem;
}

class TypedEventEmitter extends EventEmitter {
  emit<K extends keyof AppEvents>(event: K, data: AppEvents[K]): boolean {
    return super.emit(event, data);
  }

  on<K extends keyof AppEvents>(
    event: K,
    listener: (data: AppEvents[K]) => void
  ): this {
    return super.on(event, listener);
  }
}

const InspectionsEvent = new TypedEventEmitter();

export default InspectionsEvent;
