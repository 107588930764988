import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { CarPart } from '../../../graphql-types';

export enum Keys {
  PartId = 'partId',
  DamageId = 'damageId',
  ActionId = 'actionId',
  Comment = 'comment',
  Price = 'price',
  Photos = 'photos',
  Arbitrage = 'arbitrage',
}

export const Schema = z.object({
  [Keys.PartId]: z
    .object({
      key: z.string(),
      item: z.object({}).optional(),
      label: z.string(),
    })
    .required()
    .nullable(),
  [Keys.DamageId]: z
    .object({
      key: z.string(),
      item: z.object({}).optional(),
      label: z.string(),
    })
    .required()
    .nullable(),
  [Keys.ActionId]: z
    .object({
      key: z.string(),
      label: z.string(),
    })
    .nullable(),
  [Keys.Comment]: z.string(),
  [Keys.Price]: z.string(),
  [Keys.Photos]: z
    .array(
      z.object({
        id: z.number(),
        _id: z.string(),
        zone: z.string(),
        src: z.string(),
      })
    )
    .min(1, 'common.validation.required'),
  [Keys.Arbitrage]: z.boolean(),
});

export type FormType = Omit<
  z.infer<typeof Schema>,
  'partId' | 'damageId' | 'actionId'
> & {
  [Keys.PartId]: {
    key: string;
    item?: CarPart;
    label: string;
  } | null;
  [Keys.DamageId]: {
    key: string;
    item?: {
      id: string;
      actions: any[];
      labels: any;
    };
    label: string;
  } | null;
  [Keys.ActionId]: {
    key: string;
    item?: any;
    label: string;
  } | null;
};

export const defaultValues: FormType = {
  [Keys.PartId]: null,
  [Keys.DamageId]: null,
  [Keys.ActionId]: null,
  [Keys.Comment]: '',
  [Keys.Price]: '',
  [Keys.Photos]: [],
  [Keys.Arbitrage]: false,
};

export const useConditionForm = (values: FormType = defaultValues) => {
  return useForm<FormType>({
    mode: 'onBlur',
    resolver: zodResolver(Schema),
    defaultValues: { ...defaultValues, ...values },
  });
};
