import {
  AppraisalPhoto,
  AppraisalVehByIdCoreDocument,
  AppraisalVehByIdCoreQuery,
  AppraisalVehByIdCoreQueryVariables,
  useUploadAppraisalPhotoReactiveMutation,
} from '../../graphql-types';
import useStore from '../../store/pe-edit-appraisal.store';

const useUploadInspectionImage = () => {
  const { setAppraisalPhoto } = useStore();

  const [uploadInspectionImage] = useUploadAppraisalPhotoReactiveMutation({
    update: (cache, result) => {
      const photo = result.data?.uploadAppraisalPhotoReactive.result;

      if (photo && photo.id) {
        const query = cache.readQuery<
          AppraisalVehByIdCoreQuery,
          AppraisalVehByIdCoreQueryVariables
        >({
          query: AppraisalVehByIdCoreDocument,
          variables: { id: `${photo.id}` },
        });

        if (!query) return;

        const data = {
          ...query,
          appraisal: {
            ...query.appraisalVehByIdCore,
            result: {
              ...query.appraisalVehByIdCore.result,
              photos: [
                ...query.appraisalVehByIdCore.result.photos.filter(
                  (it) => it.zone !== photo.zone
                ),
                photo as AppraisalPhoto,
              ],
            },
          },
        };

        setAppraisalPhoto(photo);

        cache.writeQuery<
          AppraisalVehByIdCoreQuery,
          AppraisalVehByIdCoreQueryVariables
        >({
          query: AppraisalVehByIdCoreDocument,
          variables: { id: `${photo.id}` },
          data,
        });
      }
    },
  });

  return { uploadInspectionImage };
};

export default useUploadInspectionImage;
