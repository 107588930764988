import React, { FC } from 'react';
import { Controller, ControllerProps, useFormContext } from 'react-hook-form';
import {
  FormHelperText,
  FormLabel,
  RadioGroup,
  RadioGroupProps,
  Stack,
} from '@mui/material';
import BasicRadioList from '../radio-lists/BasicRadioList';
import OptionButton from '../radio-lists/OptionButton';
import { useTranslation } from 'react-i18next';
import {
  IOption,
  RadioListItemProps,
  RadioListProps,
} from 'constants/entities/ui/types';

type Props = Pick<
  ControllerProps,
  'name' | 'defaultValue' | 'shouldUnregister' | 'disabled'
> &
  RadioGroupProps & {
    label?: string;
    changeHandler?: (value: string) => false | void | undefined;
    ListComponent?: FC<RadioListProps>;
    OptionComponent?: FC<RadioListItemProps>;
    options: IOption[];
    required?: boolean;
    name?: any;
  };

const RadioGroupController: FC<Props> = ({
  name,
  defaultValue,
  shouldUnregister,
  ListComponent = BasicRadioList,
  OptionComponent = OptionButton,
  options,
  label,
  required,
  changeHandler,
  disabled,
  ...radioGroupProps
}) => {
  const { t } = useTranslation();
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      defaultValue={defaultValue}
      shouldUnregister={shouldUnregister}
      control={control}
      render={({
        field: { value, ref, onBlur, onChange },
        fieldState: { error },
      }) => (
        <Stack gap={1} width="100%">
          {label && (
            <FormLabel error={!!error?.message} required={required}>
              {t(label)}
            </FormLabel>
          )}

          <RadioGroup
            ref={ref}
            value={value}
            onBlur={onBlur}
            onChange={(e, newValue) => {
              const preventChange = changeHandler?.(newValue);
              if (preventChange !== false) onChange(e);
            }}
            {...radioGroupProps}
          >
            <ListComponent
              options={options}
              currentValue={value}
              OptionComponent={OptionComponent}
              disabled={disabled}
            />
          </RadioGroup>

          {error?.message && (
            <FormHelperText error>{t(error.message as string)}</FormHelperText>
          )}
        </Stack>
      )}
    />
  );
};

export default RadioGroupController;
