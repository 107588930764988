import { IOption } from './types';
import { EvaluationSource } from '../appraisal';

export const statusGroups = {
  drafts: ['draft', 'none'],
  waiting: ['evaluation', 'evaluating', 'new', 'waiting'],
  active: [
    'evaluation',
    'evaluating',
    'new',
    'waiting',
    'evaluated',
    'acquisition',
    'acquiring',
    'completed',
    'approved',
  ],
  openOffers: [
    'evaluated',
    'expired',
    'acquisition',
    'acquiring',
    'completed',
    'approved',
  ],
  bought: ['acquired', 'transport'],
  assess: [
    'assessment',
    'assessing',
    'assessed',
    'processing',
    'pending',
    'returned',
  ],
  ready: ['assessed', 'conditioned'],
  conditioning: ['assessed', 'conditioning'],
  conditioned: ['conditioned'],
  sold: ['sold'],
  canceled: ['returned', 'closed', 'cloned', 'cancelled', 'rejected'],
  expired: ['expired'],
  client: [
    'draft',
    'new',
    'evaluation',
    'completed',
    'evaluated',
    'cloned',
    'expired',
    'cancelled',
  ],
  appraiser: [
    'new',
    'waiting',
    'evaluating',
    'evaluated',
    'acquisition',
    'acquiring',
    'expired',
    'cancelled',
  ],
  director: [
    'approved',
    'acquiring',
    'acquisition',
    'transport',
    'acquired',
    'expired',
    'cancelled',
  ],
  assessor: ['processing', 'assessment', 'assessing', 'pending', 'assessed'],
  buyer: ['evaluating', 'evaluated'],
};

export const EvaluationSourceOptions: IOption[] = [
  { value: EvaluationSource.Exchange, label: 'origins.exchange' },
  { value: EvaluationSource.Buy, label: 'origins.buy' },
  { value: EvaluationSource.Service, label: 'origins.service' },
  { value: EvaluationSource.Inventory, label: 'origins.inventory' },
];

export const VehicleColors = [
  'Beige/Tan',
  'Black Metallic',
  'Blue',
  'Bronze',
  'Brown',
  'Champagne',
  'Charcoal',
  'Gold',
  'Gray',
  'Green',
  'Maroon',
  'Orange',
  'Pearl',
  'Pink',
  'Purple',
  'Red',
  'Silver',
  'White',
  'Yellow',
];

export const VehicleColorsOptions: IOption[] = VehicleColors.map((color) => ({
  value: color,
  label: `colors.${color.toLowerCase()}`,
}));

export const sideIconMap: { [key: string]: string } = {
  front: 'assets/icons/Voiture-Front.png',
  left: 'assets/icons/Voiture-left.png',
  back: 'assets/icons/Voiture-Back.png',
  right: 'assets/icons/Voiture-right.png',
  top: 'assets/icons/Voiture-top.png',
  interior: 'assets/icons/Voiture-inside.png',
  thread: 'assets/icons/Pneu.png',
  mechanic: 'assets/icons/mechanic.png',
  vehicle: 'assets/icons/car-repair.png',
  'direction-first': 'assets/icons/vehicle-report/RotuleExterieureGauche.svg',
  'direction-second': 'assets/icons/vehicle-report/ConduitsRaccordsHaut.svg',
  'direction-third': 'assets/icons/vehicle-report/LevierCommande.svg',
  'engine-exhaust-transmission-one':
    'assets/icons/vehicle-report/TémoinDéfaillance.svg',
  'engine-exhaust-transmission-two':
    'assets/icons/vehicle-report/Résonateur.svg',
  'electrical-system': 'assets/icons/vehicle-report/CableBatterie.svg',
  'security-first': 'assets/icons/vehicle-report/MecanismeSiegeArriereGa.svg',
  'security-second': 'assets/icons/vehicle-report/CoussinGonflable.svg',
  maintenance: 'assets/icons/vehicle-report/HuileMoteur.svg',
  accessories: 'assets/icons/vehicle-report/Radio.svg',
  'lighting-and-signaling-first': 'assets/icons/vehicle-report/TableauBord.svg',
  'lighting-and-signaling-second': 'assets/icons/vehicle-report/Réflecteur.svg',
  'tires-wheels': 'assets/icons/vehicle-report/JantesMagsRouesAcier.svg',
  'brakes-fitst': 'assets/icons/vehicle-report/DisquesAV.svg',
  'brakes-second': 'assets/icons/vehicle-report/DisquesAR.svg',
  'class-and-mirrors': 'assets/icons/vehicle-report/Pare-briseAV.svg',
  'suspension-first': 'assets/icons/vehicle-report/Suspension.svg',
  'suspension-second': 'assets/icons/vehicle-report/BielletteRaccordement.svg',
};
