import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';

export enum Keys {
  Odometer = 'odometer',
  OdometerMiles = 'odometerMiles',
  Comment = 'comment',
  HasEngineNoise = 'hasEngineNoise',
  AirConditionWorks = 'airConditionWorks',
  KeysCount = 'keysCount',
  NeedCompound = 'needCompound',
}

export const Schema = z.object({
  [Keys.Odometer]: z.string(),
  [Keys.OdometerMiles]: z.string(),
  [Keys.Comment]: z.string(),
  [Keys.HasEngineNoise]: z.string().nullable(),
  [Keys.AirConditionWorks]: z.string().nullable(),
  [Keys.KeysCount]: z.string().nullable(),
  [Keys.NeedCompound]: z.string().nullable(),
});

export type FormType = z.infer<typeof Schema>;

export const defaultValues: FormType = {
  [Keys.Odometer]: '',
  [Keys.OdometerMiles]: '',
  [Keys.Comment]: '',
  [Keys.HasEngineNoise]: null,
  [Keys.AirConditionWorks]: null,
  [Keys.KeysCount]: null,
  [Keys.NeedCompound]: null,
};

export const useMechanicForm = (values: FormType = defaultValues) => {
  return useForm<FormType>({
    mode: 'onBlur',
    resolver: zodResolver(Schema),
    defaultValues: { ...defaultValues, ...values },
  });
};
